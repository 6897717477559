import {FC} from 'react'
import { TableDefault } from '../../../../../../components/Table/TableDefault'
import { IManagerTable } from './IManagerTable'
import { TableItem } from '../../../../../../components/Table/ui/TableItem'
import { IStrategyField } from '../../../../../../strategy/interface/IStrategyField'
import { getMangerRowStaistics } from './shared/dataList'
import { Box, Typography } from '@mui/material'
import { priceTransormFormat } from '../../../../../../shared/functions/functions'
import { Diamond, Redeem } from '@mui/icons-material'
import channelStore from "../../../../../../store/channel.store";

export const ManagerTable:FC<IManagerTable> = (props) => {
  const {manager, savingDepartment} = props

  const talbleCol:IStrategyField[] = [
    {code:'type', title:'Тип', immutable:false, isSimple:false, isChannelType:true,isShowTypeText:true},
    {code:'cnt', title:'Каналов', immutable:false, isSimple:true},
    {code:'totalPrice', title:'Стоимость', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'totalOrders', title:'Заказы', immutable:false, isSimple:true},
    {code:'averageCheck', title:'Ср. чек в интеграциях', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'totalOrdersSum', title:'Сумма', immutable:false, isSimple:true, isMoneySimple:true},
    {code:'averageRoi', title:'ROI', immutable:false, isSimple:true, isPercent:true, isShowRemains:false},
    {code:'averageOrderPrice', title:'Стоимость заказа', immutable:false, isSimple:true, isMoneySimple:true},
  ]

  return (
    <Box>
      <Box sx={{mb:2}}>
        <Typography display={'inline-block'} sx={{fontWeight:'bold', mr:0.5}}>
          {manager.manager?.firstName} {manager.manager?.lastName}
        </Typography>
        {manager.saving ? 
          <Box sx={{display:'inline-block', mr:0.5}}>
            <Diamond sx={{verticalAlign:'top', color:'rgb(248, 117, 1)', mr:0.5}}/> 
            <Typography display={'inline-block'}>Экономия: {priceTransormFormat(manager.saving, true)}</Typography>
          </Box>
        : null}
        {savingDepartment ? 
          <Box sx={{display:'inline-block'}}>
            <Redeem sx={{verticalAlign:'top', color:'rgb(248, 117, 1)', mr:0.5}}/> 
            <Typography display={'inline-block'}>Экономия отдела: {priceTransormFormat(savingDepartment, true)}</Typography>
          </Box>
        : null}
      </Box>
      {channelStore.channels &&
        <TableDefault tableRow={talbleCol} showBorderRadius={false}>
          {getMangerRowStaistics(manager, channelStore.channels).map((item, i)=><TableItem key={i} cells={talbleCol} {...item} sx={{p:1, pt:1,pb:1}}/>)}
        </TableDefault>
      }
    </Box>
  )
}
