import { IGoodItem } from "../../../components/Table/ui/Fields/GoodsField/ui/GoodItem/IGoodItem";

export const checkRequiredSubmit = (
  promokod: string,
  source: string,
  initialPrice: string,
  price: string,
  date: string,
  goods: IGoodItem[]
): boolean => {
  if (
    promokod?.trim() !== "" &&
    source?.trim() !== "" &&
    initialPrice?.trim().length > 0 &&
    initialPrice !== "0" &&
    price?.trim().length > 0 &&
    price !== "0" &&
    date?.trim() !== "" &&
    goods?.length > 0
  ) {
    return true;
  } else return false;
};
