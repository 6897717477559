import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IIntegrationTableList } from '../../../../../../components/IntegrationTable/models/IIntegrationTableList';
import dashboardStore from '../../../../../../store/dashboard.store';
import { AgreementTableItem } from '../AgreementTableItem/AgreementTableItem';

interface IAgreemenTableListArr extends IIntegrationTableList {
  navigateAside:(id:string)=>void;
}

export const AgreemenTableListArr:FC<IAgreemenTableListArr> = observer((props) => {
  const {tableHead, navigateAside} = props

  return (
    <>
      {dashboardStore.agreementsAll?.map(item=>
        <AgreementTableItem
          key={item.id}
          cells={tableHead}
          agreementId={item.id}
          userApprovments={item.approvments}
          integrationId={item?.integration?.id}
          onNavigate={navigateAside}
          {...item?.integration?.channel}
          integrationPrice={item.integration?.price}
          agreementStatus={item.agreementStatus?.code}
          integrationDate={item.integration?.integration_date}
          integrationProps={item.integration}
          createdAt={item.createdAt}
          allDecisionDate={item.allDecisionDate}
        />)
      }
    </>
  )
})