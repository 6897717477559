import { FC, useState, useEffect } from "react";
import { IPredictionField } from "./IPredictionField";
import { Box, Stack, Typography } from "@mui/material";
import { InputEditField } from "../InputEditField/InputEditField";
import IntegrationsService from "../../../../../services/integration/integrations.service";
import { IUpdateStatusesRequest } from "../../../../../services/integration/IntegrationsRequest";
import { AxiosError } from "axios";
import integrationTableStore from "../../../../../store/integrationTable.store";
import { priceTransormFormat } from "../../../../../shared/functions/functions";
import integrationPageStore from "../../../../../store/integrationPage.store";
import { EditIconButton } from "../../../../UI/Buttons/EditIconButton/EditIconButton";
import dashboardStore from "../../../../../store/dashboard.store";

export const PredictionField: FC<IPredictionField> = (props) => {
  const {
    id,
    update_id,
    initValue,
    field_code,
    readOnly = false,
    onChangeUpdateId,
    symbol,
    showRemains,
  } = props;

  const [value, setValue] = useState<string>(initValue?.toString());

  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const submit = (newValue: string) => {
    setLoading(true);

    const option: IUpdateStatusesRequest = {
      id,
      update_id,
      field: field_code,
      new_value: newValue,
    };
    IntegrationsService.updatePredictions(option)
      .then((res) => {
        // console.log(res, 'update pridict field', field_code)
        const options: IUpdateStatusesRequest[] = [
          {
            field: "prediction_views",
            new_value: res.data.prediction_views,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_traffic",
            new_value: res.data.prediction_traffic,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_cpv",
            new_value: res.data.prediction_cpv || 0,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_cpv_new",
            new_value: res.data.prediction_cpv_new,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_ctr",
            new_value: res.data.prediction_ctr,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_cr",
            new_value: res.data.prediction_cr,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_orders",
            new_value: res.data.prediction_orders,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_roi",
            new_value: res.data.prediction_roi,
            id,
            update_id: res.data.update_id,
          },
          {
            field: "prediction_roi_new",
            new_value: res.data.prediction_roi_new,
            id,
            update_id: res.data.update_id,
          },
        ];

        options.forEach((option) => {
          dashboardStore.changeField(option);
          integrationTableStore.updatePrediction(option);
        });
        setValue(newValue);

        integrationPageStore.setAverageOrderSumm(res.data.averageCheck);
        integrationPageStore.setPredictionViews(res.data.prediction_views);
        integrationPageStore.setPredictionTraffic(res.data.prediction_traffic);
        integrationPageStore.setPredictionCpv(res.data.prediction_cpv);
        integrationPageStore.setPredictionCpvNew(res.data.prediction_cpv_new);
        integrationPageStore.setPredictionCtr(res.data.prediction_ctr);
        integrationPageStore.setPredictionCr(res.data.prediction_cr);
        integrationPageStore.setPredictionOrders(res.data.prediction_orders);
        integrationPageStore.setPredictionRoi(res.data.prediction_roi);
        integrationPageStore.setPredictionRoiNew(res.data.prediction_roi_new);

        if (onChangeUpdateId) {
          onChangeUpdateId(res.data.update_id);
        }

        setLoading(false);
        setEdit(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err update predicti', field_code)
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setValue(initValue?.toString());
  }, [initValue]);

  return (
    <Box>
      {!isEdit && (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography noWrap>
            {priceTransormFormat(value, false, showRemains, showRemains)} {symbol ? symbol : null}
          </Typography>
          {!readOnly && <EditIconButton size="small" onClick={() => setEdit(true)} />}
        </Stack>
      )}
      {isEdit && (
        <InputEditField
          inputType="number"
          initValue={value?.toString()}
          loading={isLoading}
          onClose={() => setEdit(false)}
          onSubmit={submit}
          placeholder="Введите прогноз"
        />
      )}
    </Box>
  );
};
